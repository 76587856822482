import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface OctActivityData {
  year_id: number;
  activity_name: string;
  points: number;
  status: number;
  [key: string]: string | number;
}

interface State {
  data: OctActivityData;
}

const AddNewActivity = () => {
  const initialState = {
    data: {
      year_id: 0,
      activity_name: '',
      points: 0,
      status: 0,
    },
  };

  const { locale } = useContext(LocaleContext);
  const [activityData, setActivityData] = useState<State>(initialState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const query = useAuthenticatedQuery(
    ['Activites', id],
    {
      url: `${fixed}admin/en/activities/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
        setActivityData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['uupdate-activities', 'PUT'] : ['add-activities', 'POST'],
    (data) => ({
      url: `${fixed}admin/${locale}/activities${isUpdateForm ? `/${id}` : ''}`,
      method: isUpdateForm ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
    }),
  );

  const {
    data: yearsData,
    error: yearsError,
    isPending: yearsPending,
  } = useAuthenticatedQuery(['fetch-years', 'GET'], {
    url: `${fixed}admin/en/calender_years`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface YearData {
    id: number;
    year_id: string;
    year: string;
  }

  if (yearsPending) return <AdminPageLoader />;
  if (yearsError) return <div>Error: {yearsError.message}</div>;
  if (yearsData && !yearsData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    setActivityData((prevState) => {
      let newData = prevState.data;
      newData = {
        ...prevState.data,
        [name]: value,
      };

      return {
        data: newData,
      };
    });
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...activityData };

    appendToFormData(formData, data, 'data');

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/all-activites');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6">
            {' '}
            {isUpdateForm ? 'Edit October Pledge Video' : 'Add October Pledge Video'}{' '}
          </Typography>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              fullWidth
              labelId="year-label"
              name="year_id"
              size="small"
              value={activityData.data.year_id === 0 ? '' : activityData.data.year_id.toString()}
              onChange={handleChange}
              required={locale === 'en'}
            >
              {(yearsData.data as YearData[]).map((year: YearData) => (
                <MenuItem key={year.id} value={year.id.toString()}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              Activity Name <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add Activity Name"
              size="small"
              name="activity_name"
              value={activityData.data.activity_name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              Points <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              type="number"
              placeholder="Add Activity Points"
              size="small"
              name="points"
              value={activityData.data.points}
              onChange={handleChange}
              fullWidth
            />
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                checked={Boolean(activityData.data.status)}
                disabled={locale !== 'en'}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddNewActivity;
