export const clientId = import.meta.env.VITE_CLIENT_ID;
export const redirectUri = import.meta.env.VITE_REDIRECT_URI ?? 'http://localhost:5000';
export const fixed = import.meta.env.VITE_API_URL ?? 'http://localhost:3001/api/';
export const publicURL = 'https://storage.googleapis.com/abi-ghq-01190-cybersecurity.appspot.com/assets/public/';
export const getImage = 'https://storage.googleapis.com/abi-ghq-01190-cybersecurity.appspot.com/assets/';
export const oldAssets = 'https://storage.googleapis.com/abi-ghq-01190-cybersecurity.appspot.com/public/storage/';
export const gameUrl = 'https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public/storage/cyber-games/';
export const DataProtectionURL = 'https://storage.googleapis.com/abi-ghq-cybersecurity-web-training/';
export const localAssets = '../images_public/';
export const downloadPDF = 'http://localhost:3001/';
export const gispVideos = "https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public/storage/cyber-security/videos/GISP Video/";
export const donloadDoc = 'https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public/storage/meme-document/'
export const downloadPhisingDoc = 'https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public'


