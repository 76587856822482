import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
  } from '@mui/material';
  import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
  import AdminWrapper from 'csam/admin/components/AdminWrapper';
  import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
  import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
  import AdminInternalError from 'csam/admin/pages/AdminInternalError';
  import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
  import LocaleContext from 'csam/components/LocaleContext';
  import { fixed, getImage } from 'csam/utils/Constants';
  import React, { useContext, useEffect, useState } from 'react';
  import { useNavigate, useParams } from 'react-router-dom';
  import { toast } from 'react-toastify';
  
  interface GamesData {
    year_id: number;
    status: number;
    is_new_game: number;
    game_title: string;
    link: string;
    image: string | File;
    [key: string]: string | number | File;
  }
  
  interface TranslationData {
    image: string | File;
    game_title: string;
    link: string;
    [key: string]: unknown;
  }
  
  interface State {
    data: GamesData;
    translations: {
      es: TranslationData;
      pt: TranslationData;
      zh: TranslationData;
    };
  }
  
  const AddOctGames = () => {
    const initialState = {
      data: { year_id: 0, game_title: '', link: '', image: '', status: 0, is_new_game: 0 },
      translations: {
        es: { image: '', game_title: '', link: '' },
        pt: { image: '', game_title: '', link: '' },
        zh: { image: '', game_title: '', link: '' },
      },
    };
  
    const [gamesData, setGamesData] = useState<State>(initialState);
    const { locale } = useContext(LocaleContext);
    const [loading, setLoading] = useState(false);
    const imageName = gamesData.data.image;
  
    const navigate = useNavigate();
    const { id } = useParams<{ id?: string }>();
  
    const slug = `oct_games`;
  
    const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
      slug,
      initialState,
    );
  
    useEffect(() => {
      if (!id) {
        setLocale('en');
      }
    }, [id, setLocale]);
  
    const query = useAuthenticatedQuery(
      ['oct_games', id],
      {
        url: `${fixed}admin/en/oct_games/${id}`,
      },
      {
        enabled: !!id,
      },
    );
  
    useEffect(() => {
      if (query.data && query.data.success) {
        setGamesData(query.data.data as State);
      }
    }, [query.data]);
  
    const isUpdateForm = Boolean(id);
  
    const mutation = useAuthenticatedMutation(
      isUpdateForm ? ['uupdate-game', 'PUT'] : ['add-game', 'POST'],
  
      (data) => {
        const headers = data instanceof FormData ? {} : { 'Content-Type': 'application/json' };
  
        return {
          url: `${fixed}admin/${locale}/oct_games${isUpdateForm ? `/${id}` : ''}`,
  
          method: isUpdateForm ? 'PUT' : 'POST',
  
          headers,
  
          data,
        };
      },
    );
  
    const {
      data: yearsData,
      error: yearsError,
      isPending: yearIsPending,
    } = useAuthenticatedQuery(['fetch-years', 'GET'], {
      url: `${fixed}admin/en/calender_years`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
  
    interface YearData {
      id: number;
      year_id: string;
      year: string;
    }
  
    if (translatePending) return <AdminPageLoader />;
    if (errorTranslate) return <Box>Error: {errorTranslate.message}</Box>;
    if (translateData && !translateData.success) return <AdminInternalError />;
  
    if (yearIsPending) return <AdminPageLoader />;
    if (yearsError) return <Box>Error: {yearsError.message}</Box>;
    if (yearsData && !yearsData.success) return <AdminInternalError />;
  
    const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
      // eslint-disable-next-line prefer-const
      let { name, value, checked } = event.target;
  
      if (name === 'status' || name === 'is_new_game') {
        value = checked ? 1 : 0;
      }
  
      if (translateData) {
        const translatableFields = translateData.data;
  
        setGamesData((prevState) => {
          let newData = prevState.data;
          let newTranslations = prevState.translations;
  
          if (locale !== 'en' && translatableFields.includes(name)) {
            newTranslations = {
              ...prevState.translations,
              [locale as keyof typeof prevState.translations]: {
                ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
                [name]: value,
              },
            };
          } else {
            newData = {
              ...prevState.data,
              [name]: value,
            };
          }
  
          return {
            data: newData,
            translations: newTranslations,
          };
        });
      }
    };
  
    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const date = new Date().toISOString();
        const tableName = 'games';
        let fileName;
  
        if (locale === 'en') {
          fileName = `${date}_${tableName}_${file.name}`;
        } else {
          fileName = `${locale}_${tableName}_${file.name}`;
        }
  
        const newFile = new File([file], fileName, { type: file.type });
  
        setGamesData((prevData) => {
          let newData = { ...prevData.data };
          let newTranslations = { ...prevData.translations };
          if (locale === 'en') {
            newData = {
              ...newData,
              [event.target.name]: newFile,
            };
          } else {
            newTranslations = {
              ...newTranslations,
              [locale]: {
                ...(newTranslations[locale as keyof State['translations']] || {}),
                [event.target.name]: newFile,
              },
            };
          }
          return {
            data: newData,
            translations: newTranslations,
          };
        });
      }
    };
  
    const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
      Object.entries(data).forEach(([key, value]) => {
        const fullKey = `${baseKey}[${key}]`;
  
        if (value instanceof File) {
          formData.append(fullKey, value);
        } else if (typeof value === 'object' && value !== null) {
          formData.append(fullKey, JSON.stringify(value));
        } else {
          formData.append(fullKey, String(value));
        }
      });
    };
  
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      setLoading(true);
  
      const formData = new FormData();
  
      const data = { ...gamesData };
  
      if (data) {
        delete data.translations;
      }
  
      appendToFormData(formData, data, 'data');
  
      if (gamesData.data.image instanceof File) {
        formData.append('image', gamesData.data.image);
      }
  
      if (gamesData?.translations) {
        Object.entries(gamesData.translations).forEach(([localeKey, translation]) => {
          appendToFormData(formData, translation, `translations[${localeKey}]`);
        });
      }
  
      try {
        await mutation.mutateAsync(formData, {
          onSuccess: () => {
            toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
            setTimeout(() => {
              navigate('/admin/october-games');
            }, 1000);
          },
  
          onError: () => {
            console.log('An error occurred while saving the data.');
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleSetLocale = (newLocale: string) => {
      if (locale === 'en' && newLocale !== 'en') {
        for (const key in gamesData.data) {
          if (
            gamesData.data[key] === undefined ||
            gamesData.data[key] === '' ||
            (gamesData.data[key] === 0 && key !== 'status')
          ) {
            toast.error(`Please fill in the ${key} field first`);
            return;
          }
        }
      }
  
      setLocale(newLocale);
    };
  
    return (
      <AdminWrapper>
        <Box className="pageHeader">
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Typography variant="h6"> {isUpdateForm ? 'Edit Game' : 'Add Game'} </Typography>
          </Box>
          <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
        </Box>
        <Box component="form" onSubmit={handleSubmit} encType="multipart/form-data">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <InputLabel id="year-label">Year</InputLabel>
              <Select
                fullWidth
                labelId="year-label"
                name="year_id"
                size="small"
                value={gamesData.data.year_id === 0 ? '' : gamesData.data.year_id.toString()}
                onChange={handleChange}
                disabled={disabledFields.includes('year_id')}
                required={locale === 'en'}
              >
                {(yearsData.data as YearData[]).map((year: YearData) => (
                  <MenuItem key={year.id} value={year.id.toString()}>
                    {year.year}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
  
            <Grid item sm={6}>
              <Box className="form-group">
                <InputLabel id="monthTitle-label">
                  Game Title <Typography component="sup">{locale.toUpperCase()}</Typography>
                </InputLabel>
                <TextField
                  placeholder="Add Game Title"
                  size="small"
                  name="game_title"
                  value={
                    locale === 'en'
                      ? gamesData.data.game_title
                      : gamesData.translations[locale as keyof State['translations']]?.game_title || ''
                  }
                  onChange={handleChange}
                  disabled={disabledFields.includes('game_title')}
                  required={locale === 'en'}
                  fullWidth
                />
              </Box>
            </Grid>
  
            <Grid item sm={12}>
              <Box className="form-group">
                <InputLabel id="monthTitle-label">
                  Link <Typography component="sup">{locale.toUpperCase()}</Typography>
                </InputLabel>
                <TextField
                  placeholder="Add Link"
                  size="small"
                  name="link"
                  value={
                    locale === 'en'
                      ? gamesData.data.link
                      : gamesData.translations[locale as keyof State['translations']]?.link || ''
                  }
                  onChange={handleChange}
                  disabled={disabledFields.includes('link')}
                  required={locale === 'en'}
                  fullWidth
                />
              </Box>
            </Grid>
  
            <Grid item sm={12}>
              <Box className="form-group">
                <InputLabel>
                  image <Typography component="sup">{locale.toUpperCase()}</Typography>
                </InputLabel>
                <TextField
                  type="file"
                  name="image"
                  onChange={handleImageChange}
                  disabled={disabledFields.includes('image')}
                  required={locale === 'en' && !imageName}
                  fullWidth
                />
                {imageName && (
                  <img
                    className="formImage"
                    src={`${getImage}oct_games/${locale === 'en' ? imageName : gamesData.translations[locale as keyof typeof gamesData.translations]?.image}`}
                    alt=""
                  />
                )}
              </Box>
            </Grid>

            <Grid item sm={6}>
              <InputLabel id="status-label">Is New Game</InputLabel>
              <Grid container alignItems="center">
                <Typography>No</Typography>
                <Switch
                  id="status-label"
                  name="is_new_game"
                  onChange={handleChange}
                  checked={Boolean(gamesData.data.is_new_game)}
                  disabled={locale !== 'en'}
                />
                <Typography>Yes</Typography>
              </Grid>
            </Grid>
  
            <Grid item sm={6}>
              <InputLabel id="status-label">Status</InputLabel>
              <Grid container alignItems="center">
                <Typography>Inactive</Typography>
                <Switch
                  id="status-label"
                  name="status"
                  onChange={handleChange}
                  checked={Boolean(gamesData.data.status)}
                  disabled={locale !== 'en'}
                />
                <Typography>Active</Typography>
              </Grid>
            </Grid>
  
            <Grid item sm={12}>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </AdminWrapper>
    );
  };
  
  export default AddOctGames;
  