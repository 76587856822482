import { Box, Container, Grid, Typography, Button } from '@mui/material';
import OctMemeReelsTranslations from 'csam/translations/OctMemeReelsTranslations';
import OctPhishingContestTranslations from 'csam/translations/OctPhishingContestTranslations';
import { donloadDoc } from 'csam/utils/Constants';
// import { downloadPDF } from 'csam/utils/Constants';
import React from 'react';

interface PhishingGuidelineProps {
  documentData: any;
}

const OctPhishingKeyFetures: React.FC<PhishingGuidelineProps> = ({ documentData }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isPhotoContest = urlParams.get('type') === 'photo-contest';

  const localeFromStorage = localStorage.getItem('locale') ?? 'en';
  const getTranslation = (key: keyof (typeof OctPhishingContestTranslations)['en']) => {
    const translations = isPhotoContest ? OctMemeReelsTranslations : OctPhishingContestTranslations;
    return translations[localeFromStorage as keyof typeof translations]?.[key] || translations.en[key];
  };

  // const isDocAvailable = documentData.contestDocument;

  // const handleDownload = async () => {
  //   const fileName = documentData.contestDocument.image;
  //   const token = sessionStorage.getItem('Auth_token');

  //   if (!token) {
  //     console.error('No token found in session storage');
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`${downloadPDF}download-document?fileName=${encodeURIComponent(fileName)}`, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const blob = await response.blob();
  //     const downloadUrl = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = downloadUrl;
  //     link.download = fileName.split('/').pop() || 'document.pdf';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error('Error downloading document:', error);
  //   }
  // };

  const handleDownload = () => {
    const fileName = '/meme-reels-document/Memes-and-reels-2024-Terms-of-application.pdf';
    const link = document.createElement('a');
    link.href = fileName;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box className="keyFeturesPhishing">
      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Typography variant="h6">{getTranslation('heading1')}</Typography>
        <Typography variant="h6">{getTranslation('heading2')}</Typography>
        <Typography variant="h6" sx={{ mt: 5}}>{getTranslation('heading5')}</Typography>
        <Typography variant="body1" sx={{ fontWeight: 500, mt: 2, px: 25 }}>
          {getTranslation('heading4')}
        </Typography>
        <Box className="text-center pb-5 mt-4">
          {isPhotoContest && (
            <Button
              variant="contained"
              color="primary"
              sx={{ fontWeight: 600, color: '#000' }}
              onClick={handleDownload}
            >
              {getTranslation('guidelineButtonText')}
            </Button>
          )}
        </Box>
      </Container>

      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-1.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                {getTranslation('cardText1')}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-2.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                {getTranslation('cardText2')}
              </Typography>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            <Box className="pledgeCard">
              <img src="/images_public/email-icon-3.png" alt="Pledge Icon" />
              <Typography variant="body1" mt={4}>
                {getTranslation('cardText3')}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ pb: 4, pt: 4, textAlign: 'center' }}>
        <Box className="do-your-part">
          <Typography component="span">{getTranslation('contestTags')}</Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default OctPhishingKeyFetures;
