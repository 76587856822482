import { Box, Container, Typography } from '@mui/material';
import { useAuthenticatedQuery } from 'csam/api/api';
import PageLoader from 'csam/components/common/PageLoader';
import Footer from 'csam/components/Footer';
import Headerdark from 'csam/components/HeaderDark';
import LocaleContext from 'csam/components/LocaleContext';
import InternalServerError from 'csam/pages/InternalServerError';
import GamesSingleTranslations from 'csam/translations/GamesSingleTranslations';
import { fixed, gameUrl, getImage } from 'csam/utils/Constants';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface BannerData {
  id: number;
  year_id: number;
  heading: string;
  image: string;
  sub_heading: string;
  description: string;
  success: boolean;
}

interface OctGameData {
  id: number;
  game_title: string;
  link: string;
  image: string;
  success: boolean;
}

const OctGameSingle:React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [sourceLink, setSourceLink] = useState(queryParams.get('link'));
  const contextLocale = useContext(LocaleContext);
  const [locale, setLocale] = useState(localStorage.getItem('locale') || contextLocale);
  const localeFromStorage = localStorage.getItem('locale') || 'en';

  const pageName = 'Games Single Page';
  const encodedPage = encodeURIComponent(pageName);

  const isSpecificGame = ['gisp_game', 'is_it_phishy', 'penalty-shootout'].some((game) => sourceLink?.includes(game));
  const gameFile = isSpecificGame ? 'index.html' : 'story.html';

  const {
    isPending: bannerPending,
    error: bannerError,
    data: bannerData,
  } = useAuthenticatedQuery<BannerData>(['Banner', 'BannerData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/banner?page=${encodedPage}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const gameId = sessionStorage.getItem('gameId');

  const {
    isPending: octgamePending,
    error: octgameError,
    data: octgameData,
  } = useAuthenticatedQuery<OctGameData>(['OctGameSingle', 'OctGameSingleData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/october-game-single/${gameId}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  useEffect(() => {
    document.body.classList.add('octMonthLandingPage');

    return () => {
      document.body.classList.remove('octMonthLandingPage');
    };
  }, []);

  useEffect(() => {
    const gameIdFromParams = queryParams.get('id');
    if (gameIdFromParams) {
      sessionStorage.setItem('gameId', gameIdFromParams); 
    }
  }, [queryParams]);
  

  useEffect(() => {
    const storedLocale = localStorage.getItem('locale');
    if (storedLocale) {
      setLocale(storedLocale);
    } else {
      setLocale(contextLocale);
    }
  }, [contextLocale]);

  useEffect(() => {
    if (octgameData?.data?.link) {
      setSourceLink(octgameData.data.link); 
      const newUrl = `/${locale}/2024/oct-game-page?link=${octgameData.data.link}&id=${gameId}`;
      navigate(newUrl, { replace: true });
      sessionStorage.setItem('link', octgameData.data.link); 
    }
  }, [octgameData, locale, navigate, gameId]);
  

  const getTranslation = (key: keyof (typeof GamesSingleTranslations)['en']) =>
    GamesSingleTranslations[locale as keyof typeof GamesSingleTranslations]?.[key] || GamesSingleTranslations.en[key];

  if (bannerPending) return <PageLoader />;
  if (bannerError) return <Box>Error: {bannerError.message}</Box>;
  if (bannerData && !bannerData.success) return <InternalServerError />;

  if (octgamePending) return <PageLoader />;
  if (octgameError) return <Box>Error: {octgameError.message}</Box>;
  if (octgameData && !octgameData.success) return <InternalServerError />;
  

  const banner = bannerData.data;
  return (
    <Box className="bg-black">
      <Headerdark />

      {banner.year_id === 1 ? (
        <Box
          className="gamebanner"
          {...(banner.image ? { style: { backgroundImage: `url(${getImage}banners/${banner.image})` } } : {})}
        />
      ) : (
        <Box
          className="gamebanner"
          {...(banner.image ? { style: { backgroundImage: `url(${getImage}banners/${banner.image})` } } : {})}
        >
          <Container>
            <Box className="banner_title">
              <Typography component="h4"> {banner.heading} </Typography>
              <Typography component="h2"> {banner.sub_heading} </Typography>
              <Typography variant="body1">{banner.description}</Typography>
            </Box>
          </Container>
        </Box>
      )}

      <Container sx={{ pt: 5 }}>
        <Typography className="common_title" sx={{ mb: 5 }} variant="commonTitle" component="h4">
          {getTranslation('sectionHeading')}
        </Typography>
        <Box className="iframeOuter">
          <iframe title="Play Game" src={`${gameUrl}${sourceLink}/${gameFile}`} />
        </Box>
      </Container>

      <Footer />
    </Box>
  );
};

export default OctGameSingle;
