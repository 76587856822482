const OctPhishingContestTranslations = {
    en : {
        heading1: "Know a lot about phishing?",
        heading2: "You may be good at detecting phishing emails, but can you create the perfect bait to hook users through phishing mail? The choice of recipe is yours…",
        heading3: "Winners will be chosen, based on the following parameters:",
        cardText1: "your phishing email can be a scam that relies on user’s fear and urgency or you may choose to pressurize them to make a payment or manipulate the user to divulge sensitive information.",
        cardText2: "Travel to the dark side and craft a seemingly genuine phishing email and stand a chance to win attractive prizes.",
        cardText3: "Play the Phishing Bait game to understand Phishing indicators. Make sure to include as many tell-tale signs of phishing as you can, the more sophisticated the better.",
        heading4: "",
        heading5: "",
        contestTags: "#DoYourPart #BeCyberSmart",
        guidelines: "Guidelines",
        listItem1: "Only ABI colleagues can participate in this contest",
        listItem2: "Only 1 entry per colleague",
        listItem3: "Submit your phishing email on the siteABI Security awareness team reserves the right to use the creative content for internal communications or awareness purposes, with due credit being assigned to the author.",
        listItem4: "Submit your phishing email on the site.",
        listItem5: "Make sure to submit your entries before",
        listItem6: "30 October, 2024",
        number1: "Uniqueness and Creativity of Idea",
        number2: "Number of phishing indicators used in the email",
        formSubHeading: "SUBMIT YOUR",
        formHeading: "ENTRIES HERE",
        label1: "Employee ID",
        label2: "Upload File",
        label3: "Comment",
        plaeholder1: "Your Employee ID Here....",
        plaeholder2: "Your Comment Here....",
        submitbtn: "Submit",
        formFooterText: "Entries once submitted cannot be edited",
        guidelineButtonText: 'For More Information Click Here',
    },
    es : {
        heading1: "¿Sabes mucho sobre phishing?",
        heading2: "Puede que seas bueno detectando correos electrónicos de phishing, pero ¿puedes crear el cebo perfecto para enganchar a los usuarios a través de un correo de phishing? La elección de la receta es tuya…",
        heading3: "Los ganadores serán seleccionados según los siguientes parámetros:",
        heading4: "",
        heading5: "",
        cardText1: "Tu correo de phishing puede ser una estafa que dependa del miedo y la urgencia del usuario, o puedes optar por presionarlo para que realice un pago o manipularlo para que divulgue información sensible.",
        cardText2: "Viaja al lado oscuro y crea un correo de phishing aparentemente genuino para tener la oportunidad de ganar premios atractivos.",
        cardText3: "Juega al juego de Cebo de Phishing para entender los indicadores de phishing. Asegúrate de incluir tantos signos reveladores de phishing como puedas; cuanto más sofisticados, mejor.",
        contestTags: "#HazTuParte #SéCiberInteligente",
        guidelines: "Directrices",
        listItem1: "Solo los colegas de ABI pueden participar en este concurso.",
        listItem2: "Solo 1 participación por colega",
        listItem3: "El equipo de concienciación sobre seguridad de ABI se reserva el derecho de utilizar el contenido creativo para comunicaciones internas o fines de concienciación, con el debido crédito asignado al autor.",
        listItem4: "Envía tu correo de phishing en el sitio.",
        listItem5: "Asegúrate de enviar tus participaciones antes de",
        listItem6: "30 de octubre de 2024",
        number1: "Unicidad y creatividad de la idea",
        number2: "Número de indicadores de phishing utilizados en el correo electrónico",
        formSubHeading: "ENVÍA TUS",
        formHeading: "ENTRADAS AQUÍ",
        label1: "Identificación del empleado",
        label2: "Subir archivo",
        label3: "Comentario",
        plaeholder1: "Tu identificación de empleado aquí....",
        plaeholder2: "Tu comentario aquí....",
        submitbtn: "Enviar",
        formFooterText: "Las entradas una vez enviadas no se pueden editar",
        guidelineButtonText: "Para más información, haz clic aquí",
    },
    pt: {
        heading1: "Sabe muito sobre phishing?",
        heading2: "Você pode ser bom em detectar e-mails de phishing, mas consegue criar a isca perfeita para fisgar usuários através de um e-mail de phishing? A escolha da receita é sua…",
        heading3: "Os vencedores serão escolhidos com base nos seguintes parâmetros:",
        heading4: "",
        heading5: "",
        cardText1: "Seu e-mail de phishing pode ser um golpe que depende do medo e da urgência do usuário, ou você pode optar por pressioná-lo a fazer um pagamento ou manipulá-lo para divulgar informações confidenciais.",
        cardText2: "Viaje para o lado sombrio e crie um e-mail de phishing aparentemente genuíno e tenha a chance de ganhar prêmios atrativos.",
        cardText3: "Jogue o jogo de Isca de Phishing para entender os indicadores de phishing. Certifique-se de incluir o máximo de sinais reveladores de phishing que puder, quanto mais sofisticados, melhor.",
        contestTags: "#FaçaSuaParte #SejaCiberInteligente",
        guidelines: "Diretrizes",
        listItem1: "Apenas os colegas da ABI podem participar deste concurso.",
        listItem2: "Apenas 1 participação por colega",
        listItem3: "A equipe de conscientização de segurança da ABI reserva-se o direito de usar o conteúdo criativo para comunicações internas ou fins de conscientização, com o devido crédito atribuído ao autor.",
        listItem4: "Envie seu e-mail de phishing no site.",
        listItem5: "Certifique-se de enviar suas participações antes de",
        listItem6: "30 de outubro de 2024",
        number1: "Unicidade e Criatividade da Ideia",
        number2: "Número de indicadores de phishing usados no e-mail",
        formSubHeading: "ENVIE SUAS",
        formHeading: "ENTRADAS AQUI",
        label1: "Identificação do funcionário",
        label2: "Carregar arquivo",
        label3: "Comentário",
        plaeholder1: "Sua identificação de funcionário aqui....",
        plaeholder2: "Seu comentário aqui....",
        submitbtn: "Enviar",
        formFooterText: "As inscrições, uma vez enviadas, não podem ser editadas",
        guidelineButtonText: "Para mais informações, clique aqui",
    },
    zh: {
        heading1: "你对网络钓鱼了解很多吗？",
        heading2: "你可能很擅长检测网络钓鱼邮件，但你能否设计出完美的诱饵，通过钓鱼邮件吸引用户？配方由你选择……",
        heading3: "获胜者将根据以下参数进行选择：",
        heading4: "",
        heading5: "",
        cardText1: "你的网络钓鱼邮件可以是依赖用户恐惧和紧迫感的骗局，或者你可以选择施压他们付款，或操纵用户泄露敏感信息。",
        cardText2: "踏入黑暗面，制作一封看似真实的网络钓鱼邮件，赢取丰厚奖品的机会。",
        cardText3: "玩网络钓鱼诱饵游戏，了解网络钓鱼的迹象。确保包含尽可能多的网络钓鱼标志，越复杂越好。",
        contestTags: "#尽你的一份力 #成为网络安全专家",
        guidelines: "指南",
        listItem1: "只有ABI同事可以参加此比赛。",
        listItem2: "每位同事仅限1次参赛",
        listItem3: "ABI 安全意识团队保留使用创意内容用于内部沟通或意识提升目的的权利，并给予作者适当的信用。",
        listItem4: "在网站上提交你的网络钓鱼邮件",
        listItem5: "确保在以下时间之前提交你的参赛作品",
        listItem6: "2024年10月30日",
        number1: "独特性和创意思想",
        number2: "电子邮件中使用的网络钓鱼指标数量",
        formSubHeading: "提交您的",
        formHeading: "参赛作品",
        label1: "员工编号",
        label2: "上传文件",
        label3: "评论",
        plaeholder1: "在此处输入您的员工编号....",
        plaeholder2: "在此处输入您的评论....",
        submitbtn: "提交",
        formFooterText: "提交后，无法编辑参赛作品",
        guidelineButtonText: "更多信息，请点击这里",
    }
}

export default OctPhishingContestTranslations;