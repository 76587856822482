import { Box, Container, Typography } from '@mui/material';
import OctMemeReelsTranslations from 'csam/translations/OctMemeReelsTranslations';
import OctPhishingContestTranslations from 'csam/translations/OctPhishingContestTranslations';
import React from 'react';

const NumbersBackground: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isPhotoContest = urlParams.get('type') === 'photo-contest';


  const localeFromStorage = localStorage.getItem('locale') ?? 'en';
  const getTranslation = (key: keyof (typeof OctPhishingContestTranslations)['en']) => {
    const translations = isPhotoContest ?  OctMemeReelsTranslations : OctPhishingContestTranslations;
    return translations[localeFromStorage as keyof typeof translations]?.[key] || translations.en[key];
  };


  return (
    <Container className="numbers-bg" sx={{ pb: 5, pt: 5 }}>
      <Box className="winners">
        <Box className="winners-main winners-phising">
          <Typography variant="h4">{getTranslation('heading3')}</Typography>
          <ul>
            <li data-aos="fade-up" data-aos-duration="1500" className="aos-init aos-animate">
              <Box className="numbers">1</Box>
              <span>
               {getTranslation('number1')}
              </span>
            </li>
            <li data-aos="fade-up" data-aos-duration="2000" className="aos-init aos-animate">
              <Box className="numbers">2</Box>
              <span>
                {getTranslation('number2')}
              </span>
            </li>
          </ul>
        </Box>
      </Box>
    </Container>
  );
};

export default NumbersBackground;
