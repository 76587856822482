import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CustomPopupTranlsations from 'csam/translations/CustomPopupTranlsations';

const CustomPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  const localeFromStorage = localStorage.getItem('locale') || 'en';

  const getTranslation = (key: keyof (typeof CustomPopupTranlsations)['en']) =>
    CustomPopupTranlsations[localeFromStorage as keyof typeof CustomPopupTranlsations]?.[key] ||
  CustomPopupTranlsations.en[key];

  useEffect(() => {
    const isFirstLoad = localStorage.getItem('isFirstLoad') === null;
    if (isFirstLoad) {
      setShowPopup(true);
      localStorage.setItem('isFirstLoad', 'false');
    }
  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleNavigate = () => {
    window.open('/en/understanding-gisp');
  };

  if (!showPopup) return null;

  return (
    <div className="custom-popup">
      <div className="custom-popup-content">
        <img src="/images_public/make-a-pledge.png" alt="Popup" />
        <p onClick={handleNavigate}> {getTranslation('heading1')} </p>
        <button onClick={handleClose}>
          {' '}
          <CloseIcon />{' '}
        </button>
      </div>
    </div>
  );
};

export default CustomPopup;
