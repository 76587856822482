import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useAuthenticatedQuery } from 'csam/api/api';
import PageLoader from 'csam/components/common/PageLoader';
import Headerdark from 'csam/components/HeaderDark';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed, getImage, gispVideos } from 'csam/utils/Constants';
import React, { useEffect } from 'react';
import InternalServerError from './InternalServerError';
import Footer from 'csam/components/Footer';
import GispTranslations from 'csam/translations/GispTranslations';

interface BannerData {
  id: number;
  sub_heading: string;
  heading: string;
  image: string;
  description: string;
  success: boolean;
}

const UnderstandingGisp: React.FC = () => {
  const { locale } = React.useContext(LocaleContext);
  const localeFromStorage = localStorage.getItem('locale') || 'en';

  const getTranslation = (key: keyof (typeof GispTranslations)['en']) =>
    GispTranslations[localeFromStorage as keyof typeof GispTranslations]?.[key] || GispTranslations.en[key];

  useEffect(() => {
    document.body.classList.add('understanding-gisp');
    return () => {
      document.body.classList.remove('understanding-gisp');
    };
  }, []);

  const {
    isPending: bannerPending,
    error: bannerError,
    data: bannerData,
  } = useAuthenticatedQuery<BannerData>(['Banner', 'BannerData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/banner?page=${encodeURIComponent('Understanding GISP')}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (bannerPending) return <PageLoader />;
  if (bannerError) return <Box>Error: {bannerError.message}</Box>;
  if (bannerData && !bannerData.success) return <InternalServerError />;
  const banner = bannerData.data;

  const underStandingGisp = {
    en: 'Understanding GISP/Understanding GISP_English.mp4',
    es: 'Understanding GISP/Understanding GISP_Span.mp4',
    pt: 'Understanding GISP/Understanding GISP_ Port.mp4',
    zh: 'Understanding GISP/Understanding GISP_Chin.mp4',
  };

  const understandingTheAnnexus = {
    en: "Understanding the Annexures/GISP Annexures English-1080p-241003.mp4",
    es: "Understanding the Annexures/GISP Annexures Spanish-1080p-241003.mp4",
    pt: "Understanding the Annexures/GISP Annexures Portuguese-1080p-241003.mp4",
    zh: "Understanding the Annexures/GISP Annexures Chinese-1080p-241003.mp4",
  }  
  
  const understandingStandards = {
    en: "Understanding the Standards/GISP standards English-1080p-241003.mp4",
    es: "Understanding the Standards/GISP standards Spanish-1080p-241003.mp4",
    pt: "Understanding the Standards/GISP standards Portuguese-1080p-241003.mp4",
    zh: "Understanding the Standards/GISP standards Chinese-1080p-241003.mp4",
  }

  const videoPath1 = underStandingGisp[localeFromStorage] || underStandingGisp['en'];
  const videoPath2 = understandingTheAnnexus[localeFromStorage] || understandingTheAnnexus['en'];
  const videoPath3 = understandingStandards[localeFromStorage] || understandingStandards['en'];

  return (
    <Box className="bg-black">
      <Headerdark />
      <Box
        className="pagebannertools"
        {...(banner.image ? { style: { backgroundImage: `url(${getImage}banners/${banner.image})` } } : {})}
      >
        <Container>
          <Box className="banner_title">
            {banner.heading && <Typography component="h4">{banner.heading}</Typography>}
            {banner.sub_heading && <Typography component="h2">{banner.sub_heading}</Typography>}
            {banner.description && <Typography variant="body1">{banner.description}</Typography>}
          </Box>
        </Container>
      </Box>


      <Container sx={{ mt: 5 }}>
        <Typography className="common_title mb-4" variant="commonTitle" component="h4">
        {getTranslation('heading7')}
        </Typography>

        <Grid container mt={5} sx={{justifyContent: 'center'}}>
          <Grid item md={6}>
            <Card className="webinarCard">
              <CardContent>
                <video width="100%" controls>
                  <source src={`${gispVideos}${videoPath1}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 5 }}>
        <Typography className="common_title mb-4" variant="commonTitle" component="h4">
          {getTranslation('heading8')}
        </Typography>

        <Grid container mt={5} sx={{justifyContent: 'center'}}>
          <Grid item md={6}>
            <Card className="webinarCard">
              <CardContent>
                <video width="100%" controls>
                  <source src={`${gispVideos}${videoPath2}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 5 }}>
        <Typography className="common_title mb-4" variant="commonTitle" component="h4">
        {getTranslation('heading9')}
        </Typography>

        <Grid container mt={5} sx={{justifyContent: 'center'}}>
          <Grid item md={6}>
            <Card className="webinarCard">
              <CardContent>
                <video width="100%" controls>
                  <source src={`${gispVideos}${videoPath3}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      
      <Footer />
    </Box>
  );
};

export default UnderstandingGisp;
