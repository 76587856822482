import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { InteractionStatus, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import {Button, Modal, Typography} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import 'csam/App.css';
import 'csam/Custom.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FeedbackModal from 'csam/components/FeedbackModal';
import LocaleContext from 'csam/components/LocaleContext';
import router from 'csam/router';
import theme from 'csam/theme/theme';
import {Suspense, useEffect, useMemo, useState} from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// import OctoberMonthPopup from './components/OctoberMonthPopup';
import CustomPopup from './components/CustomPopup';

const App = () => {
  const [locale, setLocale] = useState('en');
  const contextValue = useMemo(() => ({ locale, setLocale }), [locale, setLocale]);
  const localeFromStorage = localStorage.getItem('locale') || locale;


  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const queryClient = new QueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // const navigateToOctoberMonth = () => {
  //   const url = `/${locale}/2024/`;
  //   window.open(url, '_blank');
  // };

  const buttonText = {
    en: 'Feedback',
    es: 'Realimentación',
    pt: 'Feedback',
    zh: '反馈',
  };

  // const octoberButtonText = {
  //   en: 'October Month',
  //   es: 'Mes de octubre',
  //   pt: 'Mês de outubro',
  //   zh: '十月',
  // };

  // const octoberButtonClass = {
  //   en: 'oct_en',
  //   es: 'oct_es',
  //   pt: 'oct_pt',
  //   zh: 'oct_zh',
  // };

  const buttonClass = {
    en: 'btn_en',
    es: 'btn_es',
    pt: 'btn_pt',
    zh: 'btn_zh',
  };

  const handleAuthError = (error: any) => {
    if (error instanceof InteractionRequiredAuthError) {
      window.location.reload();
    }
  };
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <LocaleContext.Provider value={contextValue}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{}} onError={handleAuthError}>
          <QueryClientProvider client={queryClient}>
            <Suspense>
              {/* <OctoberMonthPopup /> */}
              <CustomPopup />
              <RouterProvider router={router} />
            </Suspense>
            {/* <Button className={`octMonthBlinking ${octoberButtonClass[localeFromStorage as keyof typeof octoberButtonClass]}`} onClick={navigateToOctoberMonth}>
              {octoberButtonText[localeFromStorage as keyof typeof octoberButtonText] || octoberButtonText.en}
            </Button> */}
            <Button
              onClick={handleOpen}
              className={`doFeedBack ${buttonClass[localeFromStorage as keyof typeof buttonClass]}`}
            >
              {buttonText[localeFromStorage as keyof typeof buttonText] || buttonText.en}
            </Button>
            <FeedbackModal open={modalOpen} onClose={handleClose} />
          </QueryClientProvider>
        </MsalAuthenticationTemplate>

        {inProgress === InteractionStatus.None && !isAuthenticated && (
          <UnauthenticatedTemplate>
            <Typography>Your are not Authorized</Typography>
          </UnauthenticatedTemplate>
        )}
      </LocaleContext.Provider>
      <Modal open={isOffline} onClose={() => setIsOffline(false)}>
        <div style={{ padding: '20px', backgroundColor: 'black', margin: '20% auto', textAlign: 'center', zIndex: 9999 }}>
          <Typography variant="h6">You are not connected to the internet</Typography>
          <Button onClick={() => window.location.reload()} variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Refresh
          </Button>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default App;
