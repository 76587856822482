import { GridColDef } from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import AdminDataGrid from 'csam/admin/components/AdminDataGrid';
import AdminPageHeader from 'csam/admin/components/AdminHeader';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import { getCommonColumns } from 'csam/admin/components/CommonColumns';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

const AllActivites: React.FC = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const deleteMutation = useAuthenticatedMutation<string[], void>(['delete-activity'], (ids) => ({
    url: `${fixed}admin/${locale}/activities`,
    method: 'DELETE',
    data: { ids },
  }));

  const queryClient = useQueryClient();
  const handleDelete = (ids?: string[]) => {
    const idsToDelete = ids || selectedIds;
    deleteMutation.mutate(idsToDelete.map((id) => id.toString()) as unknown as void, {
      onSuccess: () => {
        toast.success(`Record Deleted Successfully`);
        queryClient.invalidateQueries({
          queryKey: ['ActivityData', 'ActivityPage', locale],
        });
      },
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      width: 40,
      renderCell: (params) => params.value,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 80,
      renderCell: (params) => (Number(params.value) === 1 ? 'Active' : 'Inactive'),
    },
    {
      field: 'activity_name',
      headerName: 'Activity Name',
      width: 500,
      renderCell: (params) => (params.row.activity_name ? params.row.activity_name : 'N/A'),
    },
    { field: 'points', headerName: 'Points', width: 80 },

    ...getCommonColumns({ handleDelete }),
  ];

  interface ActiviesData {
    id: number;
    activity_name: string;
    points: string;
    created_at: string;
    updated_at: string;
    status: number;
    year_id: number;
  }

  const relations = ['year'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<ActiviesData[]>(['ActivityPage', 'ActivityData', locale], {
    url: `${fixed}admin/${locale || localeFromStorage}/activities?relations=${relationsParam}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  return (
    <AdminWrapper>
      <AdminPageHeader
        title="Calendar Weeks"
        locale={locale}
        setLocale={setLocale}
        createLink="create"
        enableBulkDelete
      />
      <AdminDataGrid<ActiviesData> data={data} columns={columns} setSelectedIds={setSelectedIds} />
    </AdminWrapper>
  );
};

export default AllActivites;
