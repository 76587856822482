const GispTranslations = {
  en: {
    heading1: 'Global Information Security Policy (GISP)',
    heading2: 'Digital Ethics (GISP)',
    heading3: 'Quick Tools',
    heading4: 'Data Protection',
    heading5: 'Trainings',
    heading6: 'Trainings For External Users',
    readMore: 'Read More',
    heading7: 'Understanding GISP',
    heading8: "Annexures",
    heading9: "Standards",
  },
  es: {
    heading1: 'Política Global de Seguridad de la Información (PGSI)',
    heading2: 'Ética Digital (GISP)',
    heading3: 'Herramientas Rápidas',
    heading4: 'Protección de Datos',
    heading5: 'Capacitaciones',
    heading6: 'Capacitaciones para Usuarios Externos',
    readMore: 'Lee mas',
    heading7: 'Entendiendo el GISP',
    heading8: "Anexos",
    heading9: "Normas",
  },
  pt: {
    heading1: 'Política Global de Segurança da Informação (PGSI)',
    heading2: 'Ética Digital (PGSI)',
    heading3: 'Ferramentas Rápidas',
    heading4: 'Proteção de Dados',
    heading5: 'Treinamentos',
    heading6: 'Treinamentos para Usuários Externos',
    readMore: 'Leia mais',
    heading7: "Compreendendo o GISP",
    heading8: "Anexos",
    heading9: "Normas",
  },
  zh: {
    heading1: '全球信息安全政策 (GISP)',
    heading2: '数字伦理（GISP）',
    heading3: '快速工具',
    heading4: '数据保护',
    heading5: '培训',
    heading6: '外部用户培训',
    readMore: '阅读更多',
    heading7: "了解 GISP",
    heading8: "附录",
    heading9: "标准",
  },
};

export default GispTranslations;
