const CustomPopupTranlsations = {
    en : {
        heading1: "Hey there! Learn more about the GISP by clicking on me!",
        
    },
    es : {
        heading1: " ¡Hola! Aprende más sobre el GISP haciendo clic en mí.",
        
    },
    pt : {
        heading1: "Olá! Saiba mais sobre o GISP clicando em mim.",
        
    },
    zh : {
        heading1: "你好！点击我了解更多关于GISP的信息",
       
    }
}

export default CustomPopupTranlsations;