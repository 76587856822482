import { Box, Typography } from '@mui/material';
import Videobg from 'csam/assets/video/bg_video.mp4';
import Headerdark from 'csam/components/HeaderDark';
import errorImg from 'csam/images/error.png';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InternalServerError: React.FC = () => {
  const navigate = useNavigate();
  const localeFromStorage = localStorage.getItem('locale') ?? 'en';

  useEffect(() => {
    navigate(`/${localeFromStorage}`);
  }, [navigate]);

  return (
    <>
      <Headerdark />
      <Box className="errorWrapper">
        <video autoPlay loop muted className="errorBgVideo">
          <source src={Videobg} type="video/mp4" />
        </video>
        <Box className="errorContent">
          <img className="errorIcon" src={errorImg} alt="" />
          <Typography variant="h1">Internal Server Error</Typography>
          <Typography variant="body1">
            We apologize for the inconvenience. Our team is working to fix the issue.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default InternalServerError;