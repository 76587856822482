import { Box, Container, Typography } from '@mui/material';
import OctMemeReelsTranslations from 'csam/translations/OctMemeReelsTranslations';
import OctPhishingContestTranslations from 'csam/translations/OctPhishingContestTranslations';
import React from 'react';

const PhishingGuideline: React.FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isPhotoContest = urlParams.get('type') === 'photo-contest';

  const localeFromStorage = localStorage.getItem('locale') ?? 'en';
  const getTranslation = (key: keyof (typeof OctPhishingContestTranslations)['en']) => {
    const translations = isPhotoContest ? OctMemeReelsTranslations : OctPhishingContestTranslations;
    return translations[localeFromStorage as keyof typeof translations]?.[key] || translations.en[key];
  };

  

  return (
    <Box className="guidelines mb-5">
      <Container>
        <Box className="guidelines-main">
          <Box className="number-wrapper-1">
            <Typography component="span" className="number-wrapper">
              <Typography component="span" className="white">
                {getTranslation('guidelines')}
              </Typography>
            </Typography>
          </Box>
          <ul>
            <li>{getTranslation('listItem1')}</li>
            <li>{getTranslation('listItem2')}</li>
            <li>{getTranslation('listItem3')}</li>
            <li>{getTranslation('listItem4')}</li>
            <li>
              {getTranslation('listItem5')}
              {!isPhotoContest && <span>{getTranslation('listItem6')}</span>}
            </li>
          </ul>
        </Box>
      </Container>
    </Box>
  );
};

export default PhishingGuideline;
