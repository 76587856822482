import { useState, useContext } from 'react';
import { Box, Typography, Button, InputLabel } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { downloadPhisingDoc, fixed, getImage } from 'csam/utils/Constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subMonths } from 'date-fns';
import { CSVLink } from 'react-csv';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

type DateRange = [Date | null, Date | null];
const NoRowsOverlay = () => {
  return <Box style={{ padding: 16, textAlign: 'center' }}>No data found</Box>;
};

const OctoberPhisingContest = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [triggerQuery, setTriggerQuery] = useState(false);
  const [, setSelectedIds] = useState<string[]>([]);

  const headers = [
    { label: 'Id', key: 'id' },
    { label: 'Emp Id', key: 'emp_id' },
    { label: 'User Id', key: 'user_id' },
    { label: 'Email', key: 'email' },
    { label: 'Comment', key: 'comment' },
    { label: 'Document', key: 'image' },
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      filterable: false,
      renderCell: (params) => params.value,
      width: 40,
    },
    {
      field: 'emp_id',
      headerName: 'Emp. ID',
      filterable: false,
      renderCell: (params) => params.value,
      width: 100,
    },
    {
      field: 'user_id',
      headerName: 'User ID',
      filterable: false,
      renderCell: (params) => params.value,
      width: 80,
    },
    {
      field: 'comment',
      headerName: 'comment',
      filterable: false,
      renderCell: (params) => params.value,
      width: 320,
    },
    {
        field: 'image',
        headerName: 'Document',
        filterable: false,
        renderCell: (params) => {
          let imageUrl;
          if (params.value.includes('/storage/contests_images/')) {
            const newPath = params.value.replace('/phishing', '/phishing/');
            imageUrl = `${downloadPhisingDoc}${newPath}`;
          } else {
            imageUrl = `${getImage}oct_phishing_contests/${params.value}`;
          }
          return (
            <a href={imageUrl} target="_blank" rel="noopener noreferrer">
              {params.value}
            </a>
          );
        },
        width: 320,
      },
    {
      field: 'year',
      headerName: 'Year',
      width: 100,
      renderCell: (params) => params.row.year.year,
    },
  ];

  interface PhisingContest {
    id: number;
    emp_id: number;
    user_id: number;
    user?: { email: string }
    comment: string;
    image: string;
    created_at: string;
    year: { year: number };
    status: string;
  }

  interface UserActivity {
    id: number;
    name: string;
    email: string;
    points: number;
  }

  const relations = ['year', 'user'];
  const relationsParam = relations.join(',');

  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const { isPending, error, data } = useAuthenticatedQuery<PhisingContest>(
    ['CalendarYearPage', 'CalendarYearData', locale],
    {
      url: `${fixed}admin/${locale || localeFromStorage}/oct_phishing_contests?relations=${relationsParam}`,
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    },
  );

  const {
    isPending: isActivityPending,
    error: activityError,
    data: activityData,
  } = useAuthenticatedQuery<UserActivity>(
    ['filterActivities', 'filterActivitiesData', dateRange],
    {
      url: `${fixed}phising/filtered-contests-itmes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: {
        startDate: dateRange[0],
        endDate: dateRange[1],
      },
    },
    {
      enabled: triggerQuery && dateRange[0] !== null && dateRange[1] !== null,
    },
  );

  if (triggerQuery && isActivityPending) return <AdminPageLoader />;
  if (activityError) return <div>Error: {activityError.message}</div>;
  if (activityData && !activityData.success) return <AdminInternalError />;

  if (isPending) return <AdminPageLoader />;
  if (error) return <div>Error: {error.message}</div>;
  if (data && !data.success) return <AdminInternalError />;

  const preprocessDataForCSV = (data: PhisingContest[]) => {
    return data.map((contest) => {
      let imageUrl;
      if (contest.image.includes('/storage/contests_images/')) {
        const newPath = contest.image.replace('/phishing', '/phishing/');
        imageUrl = `${downloadPhisingDoc}${newPath}`;
      } else {
        imageUrl = `${getImage}oct_phishing_contests/${contest.image}`;
      }
      return {
        id: contest.id,
        emp_id: contest.emp_id,
        user_id: contest.user_id,
        email: contest.user ? contest.user.email : '',
        comment: contest.comment,
        image: imageUrl,
        year: contest.year.year,
      };
    });
  };
  
  const csvData = preprocessDataForCSV(
    Array.isArray(triggerQuery && activityData ? activityData.data : data?.data) 
      ? (triggerQuery && activityData ? activityData.data : data?.data) 
      : []
  );
  

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6">Phising Contest</Typography>
          <Button
            type="button"
            sx={{ color: '#fff', lineHeight: '26px' }}
            variant="contained"
            size="small"
            color="success"
            className="download-csv-button"
            title="Download CSV"
          >
            <CSVLink data={Array.isArray(csvData) ? csvData : []} headers={headers}>
  <CloudDownloadIcon sx={{ fontSize: '18px' }} /> CSV
</CSVLink>
          </Button>
        </Box>
        <Box className="d-flex gap-3 align-items-center">
          <Box>
            <InputLabel id="year-label">Select Date to filter activities</InputLabel>
             <DatePicker
              selected={dateRange[0] || undefined}
              onChange={(dates) => {
                const [start, end] = dates as [Date | null, Date | null];
                setDateRange([start, end]);
                if (start !== null && end !== null) {
                  setTriggerQuery(true);
                } else {
                  setTriggerQuery(false);
                }
              }}
              startDate={dateRange[0] || undefined}
              endDate={dateRange[1] || undefined}
              selectsRange
              monthsShown={2}
              minDate={subMonths(new Date(), 48)}
              maxDate={new Date()}
              placeholderText="Select date range"
            /> 
          </Box>
          <LocaleButtonGroup currentLocale={locale} setLocale={setLocale} />
        </Box>
      </Box>

      <Box>
        {triggerQuery ? (
          <>
            {activityData ? (
              <DataGrid
                rows={Array.isArray(activityData.data) ? activityData.data : []}
                columns={columns}
                onRowSelectionModelChange={(newSelection) => {
                  console.log(newSelection);
                  setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
                }}
                slots={{
                  noRowsOverlay: NoRowsOverlay,
                }}
              />
            ) : (
              <AdminPageLoader />
            )}
          </>
        ) : data && data.data ? (
          <DataGrid
            rows={data.data as unknown as readonly UserActivity[]}
            columns={columns}
            onRowSelectionModelChange={(newSelection) => {
              console.log(newSelection);
              setSelectedIds(newSelection.map((id) => id.toString()) as string[]);
            }}
            slots={{
              noRowsOverlay: NoRowsOverlay,
            }}
          />
        ) : (
          <AdminPageLoader />
        )}
      </Box>
    </AdminWrapper>
  );
};

export default OctoberPhisingContest;
