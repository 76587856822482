import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed, getImage } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface BannerData {
  year_id: number;
  page: string;
  sub_heading: string;
  heading: string;
  description: string;
  status: number;
  image: string | File;
  [key: string]: string | number | File;
}

interface TranslationData {
  sub_heading: string;
  heading: string;
  description: string;
  image: string | File;
  [key: string]: unknown;
}

interface State {
  data: BannerData;
  translations: {
    es: TranslationData;
    pt: TranslationData;
    zh: TranslationData;
  };
}

const AddBanner = () => {
  const initialState = {
    data: {
      year_id: 0,
      page: '',
      sub_heading: '',
      heading: '',
      description: '',
      image: '',
      status: 0,
    },
    translations: {
      es: { sub_heading: '', heading: '', description: '', image: '' },
      pt: { sub_heading: '', heading: '', description: '', image: '' },
      zh: { sub_heading: '', heading: '', description: '', image: '' },
    },
  };

  const [bannerData, setBannerData] = useState<State>(initialState);
  const { locale } = useContext(LocaleContext);
  const [loading, setLoading] = useState(false);
  const imageName = bannerData.data.image;

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const slug = `banners`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['banners', id],
    {
      url: `${fixed}admin/en/banners/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setBannerData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-banners', 'PUT'] : ['add-banners', 'POST'],

    (data) => {
      const headers = data instanceof FormData ? {} : { 'Content-Type': 'application/json' };

      return {
        url: `${fixed}admin/${locale}/banners${isUpdateForm ? `/${id}` : ''}`,

        method: isUpdateForm ? 'PUT' : 'POST',

        headers,

        data,
      };
    },
  );

  const {
    data: yearsData,
    error: yearsError,
    isPending: yearIsPending,
  } = useAuthenticatedQuery(['fetch-years', 'GET'], {
    url: `${fixed}admin/en/calender_years`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface YearData {
    id: number;
    year_id: string;
    year: string;
  }

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <Box>Error: {errorTranslate.message}</Box>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  if (yearIsPending) return <AdminPageLoader />;
  if (yearsError) return <Box>Error: {yearsError.message}</Box>;
  if (yearsData && !yearsData.success) return <AdminInternalError />;

  const pages = [
    'Home',
    'Theme of The Month',
    'Games & Contests',
    'Good Reads Updates',
    'Leaderboard',
    'GISP & Important Resources',
    'Games Single Page',
    'Data Protection',
    'Archives',
    'Understanding GISP',
  ];

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setBannerData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const date = new Date().toISOString();
      const tableName = 'banners';
      let fileName;

      if (locale === 'en') {
        fileName = `${date}_${tableName}_${file.name}`;
      } else {
        fileName = `${locale}_${tableName}_${file.name}`;
      }

      const newFile = new File([file], fileName, { type: file.type });

      setBannerData((prevData) => {
        let newData = { ...prevData.data };
        let newTranslations = { ...prevData.translations };
        if (locale === 'en') {
          newData = {
            ...newData,
            [event.target.name]: newFile,
          };
        } else {
          newTranslations = {
            ...newTranslations,
            [locale]: {
              ...(newTranslations[locale as keyof State['translations']] || {}),
              [event.target.name]: newFile,
            },
          };
        }
        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (value instanceof File) {
        formData.append(fullKey, value);
      } else if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...bannerData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (bannerData.data.image instanceof File) {
      formData.append('image', bannerData.data.image);
    }

    if (bannerData?.translations) {
      Object.entries(bannerData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/banners');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    if (locale === 'en' && newLocale !== 'en') {
      for (const key in bannerData.data) {
        if (
          key !== 'sub_heading' &&
          key !== 'heading' &&
          key !== 'description' &&
          (bannerData.data[key] === undefined ||
            bannerData.data[key] === '' ||
            (bannerData.data[key] === 0 && key !== 'status'))
        ) {
          toast.error(`Please fill in the ${key} field first`);
          return;
        }
      }
    }

    setLocale(newLocale);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit Banner' : 'Add Banner'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              fullWidth
              labelId="year-label"
              name="year_id"
              size="small"
              value={bannerData.data.year_id === 0 ? '' : bannerData.data.year_id.toString()}
              onChange={handleChange}
              disabled={disabledFields.includes('year_id')}
              required={locale === 'en'}
            >
              {(yearsData.data as YearData[]).map((year: YearData) => (
                <MenuItem key={year.id} value={year.id.toString()}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">Select Page</InputLabel>
            <Select
              fullWidth
              labelId="page-label"
              name="page"
              size="small"
              value={bannerData.data.page || ''}
              onChange={handleChange}
              disabled={disabledFields.includes('page')}
              required={locale === 'en'}
            >
              {pages.map((page) => (
                <MenuItem key={page} value={page}>
                  {page}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="monthTitle-label">
                Heading <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Heading"
                size="small"
                name="heading"
                value={
                  locale === 'en'
                    ? bannerData.data.heading
                    : bannerData.translations[locale as keyof State['translations']]?.heading || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('heading')}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box className="form-group">
              <InputLabel id="monthTitle-label">
                Sub Heading (optional) <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Sub Heading"
                size="small"
                name="sub_heading"
                value={
                  locale === 'en'
                    ? bannerData.data.sub_heading
                    : bannerData.translations[locale as keyof State['translations']]?.sub_heading || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('sub_heading')}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Box className="form-group">
              <InputLabel id="monthTitle-label">
                Description <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                placeholder="Add Description"
                size="small"
                name="description"
                type="textarea"
                multiline
                minRows={3}
                value={
                  locale === 'en'
                    ? bannerData.data.description
                    : bannerData.translations[locale as keyof State['translations']]?.description || ''
                }
                onChange={handleChange}
                disabled={disabledFields.includes('description')}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Box className="form-group">
              <InputLabel>
                Background Image <Typography component="sup">{locale.toUpperCase()}</Typography>
              </InputLabel>
              <TextField
                type="file"
                name="image"
                onChange={handleImageChange}
                disabled={disabledFields.includes('image')}
                required={locale === 'en' && !imageName}
                fullWidth
              />
              {imageName && (
                <img
                  className="formImage"
                  src={`${getImage}banners/${locale === 'en' ? imageName : bannerData.translations[locale as keyof typeof bannerData.translations]?.image}`}
                  alt=""
                />
              )}
            </Box>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                checked={Boolean(bannerData.data.status)}
                disabled={locale !== 'en'}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddBanner;
