import React, {useEffect, useState} from 'react';
import './TrainingPlatform.css';
import Logo from 'csam/images/logo_img.png';
import {Box, Button, Modal, Typography} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { fixed } from 'csam/utils/Constants';
const LocalURL = '/SecurityTraining';
const TrainingPlatformAs: React.FC = () => {
  const { accounts } = useMsal();
  const user = accounts[0];
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    color: 'text.primary',
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  async function updateCompletion() {
    try {
      await fetch(`${fixed}proxy/updateAppSecCompletion`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: user.username}),
      });
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  }

  useEffect(() => {
    document.body.classList.add('hideFeedback');
    if (location.pathname === '/en/appsec-thanks') {
      updateCompletion();
      setShowModal(true);
    } else {
      loginUser();
    }
    return () => {
      document.body.classList.remove('hideFeedback');
    };
  }, []);
  const loginUser = async (): Promise<string | null> => {
    if (!user) {
      console.error('No user found in MSAL accounts');
      return null;
    }

    const loginPayload = {
      username: user.username,
      email: user.username,
    };

    try {
      const response = await fetch(`${fixed}proxy/login-as`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginPayload)
      });

      if (!response.ok) {
        throw new Error('Failed to login');
      }
      return;
    } catch (error) {
      console.error('Error logging in:', error);
      return null;
    }
  };
  const handleClick = async (language: string) => {
    if (!user) {
      console.error('No user found in MSAL accounts');
      return;
    }
    const activity = { action: `user clicked on ${language} training` };
    try {
      await fetch(`${fixed}proxy/updateActivity`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.username, username: user.username, activity, type: 'appsec' }),
      });
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  };
  return (
    <div className="pb-5 trainingsContent">
      {' '}
      <header className="trainingsHeader">
        {' '}
        <div className="brand-logo p-5">
          {' '}
          <img src={Logo} alt="CSAM" />{' '}
        </div>{' '}
        <Typography className="common_title mb-4" variant="commonTitle" component="h4">
          {' '}
          WELCOME TO ABI 3rd PARTY LEARNING PLATFORM{' '}
        </Typography>{' '}
      </header>{' '}
      <div id="block">
        {' '}
        <fieldset id="one">
          {' '}
          <legend>Application Security Training</legend> <small className="d-block text-end">Choose a language</small>{' '}
          <a target="_blank" href={`${LocalURL}/v1_3_29Jun2023_English/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">English Module</div>{' '}
          </a>{' '}
          <a target="_blank" href={`${LocalURL}/Apr2024_Chinese/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">Chinese Module</div>{' '}
          </a>{' '}
          <a target="_blank" href={`${LocalURL}/Apr2024_Spanish/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">Spanish Module</div>{' '}
          </a>{' '}
          <a target="_blank" href={`${LocalURL}/Apr2024_Portuguese/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">Portuguese Module</div>{' '}
          </a>{' '}
        </fieldset>{' '}
      </div>{' '}
      <Modal
          open={showModal}
          onClose={() => {}}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h1" component="h1">
            Thank You!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            You have completed your training.
          </Typography>
          <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/en')}
              sx={{ mt: 2 }}
          >
            Click here to explore Cyber Security Awareness portal
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
export default TrainingPlatformAs;
