import React, { useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { fixed } from 'csam/utils/Constants';

const TrainingReports: React.FC = () => {
    useEffect(() => {
        document.body.classList.add('hideFeedback');
        return () => {
            document.body.classList.remove('hideFeedback');
        };
    }, []);

    const handleDownload = async (url: string, filename: string) => {
        try {
            const response = await fetch(fixed + url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        } catch (error) {
            console.error('Error hitting the API:', error);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
            <Box display="flex" justifyContent="space-between" width="80%" gap="20px">
                <Box display="flex" flexDirection="column" alignItems="center" gap="10px" flex="1">
                    <Typography variant="h6" component="h2">3rd Party Training</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('proxy/get-training-data/Completed', 'training-data.csv')}
                        style={{ width: '100%', height: '60px', fontWeight: 'bold' }}
                    >
                        3rdParty Completed Training Data With Saviynt
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('proxy/get-training-data/Completed without saviynt', 'training-data.csv')}
                        style={{ width: '100%', height: '60px', fontWeight: 'bold' }}
                    >
                        3rdParty Completed Training Data Without Saviynt
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('proxy/get-training-data', 'training-data.csv')}
                        style={{ width: '100%', height: '60px', fontWeight: 'bold' }}
                    >
                        Download Training Data (All)
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('proxy/download-errors', 'errors.csv')}
                        style={{ width: '100%', height: '60px', fontWeight: 'bold' }}
                    >
                        Download Errors (All)
                    </Button>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box height="100%" width="2px" bgcolor="grey.500" />
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" gap="10px" flex="1">
                    <Typography variant="h6" component="h2">AppSec Training</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('proxy/download-appssec', 'appssec.csv')}
                        style={{ width: '100%', height: '60px', fontWeight: 'bold' }}
                    >
                        Download AppSec Data (All)
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDownload('proxy/download-errors', 'errors.csv')}
                        style={{ width: '100%', height: '60px', fontWeight: 'bold' }}
                    >
                        Download Errors (All)
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default TrainingReports;