import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AdminWrapper from 'csam/admin/components/AdminWrapper';
import LocaleButtonGroup from 'csam/admin/components/LocaleButtonGroup';
import useTranslatableFields from 'csam/admin/hooks/UseTranslateble,';
import AdminInternalError from 'csam/admin/pages/AdminInternalError';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import { fixed } from 'csam/utils/Constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface OctoberWebinarData {
  year_id: number;
  title: string;
  description: string;
  video_link: string;
  status: number;
  [key: string]: string | number;
}

interface TranslationData {
  title: string;
  description: string;
  video_link: string;
  [key: string]: unknown;
}

interface State {
  data: OctoberWebinarData;
  translations: {
    es: TranslationData;
    zh: TranslationData;
    pt: TranslationData;
  };
}

const AddWebinars: React.FC = () => {
  const initialState = {
    data: {
      year_id: 0,
      title: '',
      description: '',
      video_link: '',
      status: 0,
    },
    translations: {
      es: { title: '', description: '', video_link: '' },
      pt: { title: '', description: '', video_link: '' },
      zh: { title: '', description: '', video_link: '' },
    },
  };

  const { locale } = useContext(LocaleContext);
  const [octWebinarData, setOctWebinarData] = useState<State>(initialState);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const slug = `oct_webinars`;

  const { translatePending, errorTranslate, translateData, disabledFields, setLocale } = useTranslatableFields(
    slug,
    initialState,
  );

  useEffect(() => {
    if (!id) {
      setLocale('en');
    }
  }, [id, setLocale]);

  const query = useAuthenticatedQuery(
    ['OctWebinar', id],
    {
      url: `${fixed}admin/en/oct_webinars/${id}`,
    },
    {
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (query.data && query.data.success) {
      setOctWebinarData(query.data.data as State);
    }
  }, [query.data]);

  const isUpdateForm = Boolean(id);

  const mutation = useAuthenticatedMutation(
    isUpdateForm ? ['update-oct-webinar', 'PUT'] : ['add-oct-webinar', 'POST'],
    (data) => ({
      url: `${fixed}admin/${locale}/oct_webinars${isUpdateForm ? `/${id}` : ''}`,
      method: isUpdateForm ? 'PUT' : 'POST',
      headers: { 'Content-Type': 'application/json' },
      data,
    }),
  );

  const {
    data: monthsData,
    error: monthError,
    isPending: monthIsPending,
  } = useAuthenticatedQuery(['fetch-monts', 'GET'], {
    url: `${fixed}admin/en/calender_months`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  const {
    data: yearsData,
    error: yearsError,
    isPending: yearsPending,
  } = useAuthenticatedQuery(['fetch-years', 'GET'], {
    url: `${fixed}admin/en/calender_years`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  interface YearData {
    id: number;
    year_id: string;
    year: string;
  }

  if (monthIsPending) return <AdminPageLoader />;
  if (monthError) return <div>Error: {monthError.message}</div>;
  if (monthsData && !monthsData.success) return <AdminInternalError />;

  if (yearsPending) return <AdminPageLoader />;
  if (yearsError) return <div>Error: {yearsError.message}</div>;
  if (yearsData && !yearsData.success) return <AdminInternalError />;

  if (translatePending) return <AdminPageLoader />;
  if (errorTranslate) return <div>Error: {errorTranslate.message}</div>;
  if (translateData && !translateData.success) return <AdminInternalError />;

  const handleChange = (event: { target: { name: string; value: string | number; checked?: boolean } }) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = event.target;

    if (name === 'status') {
      value = checked ? 1 : 0;
    }

    if (translateData) {
      const translatableFields = translateData.data;

      setOctWebinarData((prevState) => {
        let newData = prevState.data;
        let newTranslations = prevState.translations;

        if (locale !== 'en' && translatableFields.includes(name)) {
          newTranslations = {
            ...prevState.translations,
            [locale as keyof typeof prevState.translations]: {
              ...(prevState.translations[locale as keyof typeof prevState.translations] || {}),
              [name]: value,
            },
          };
        } else {
          newData = {
            ...prevState.data,
            [name]: value,
          };
        }

        return {
          data: newData,
          translations: newTranslations,
        };
      });
    }
  };

  const appendToFormData = (formData: FormData, data: Record<string, unknown>, baseKey: string) => {
    Object.entries(data).forEach(([key, value]) => {
      const fullKey = `${baseKey}[${key}]`;

      if (typeof value === 'object' && value !== null) {
        formData.append(fullKey, JSON.stringify(value));
      } else {
        formData.append(fullKey, String(value));
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    const data = { ...octWebinarData };

    if (data) {
      delete data.translations;
    }

    appendToFormData(formData, data, 'data');

    if (octWebinarData?.translations) {
      Object.entries(octWebinarData.translations).forEach(([localeKey, translation]) => {
        appendToFormData(formData, translation, `translations[${localeKey}]`);
      });
    }

    try {
      await mutation.mutateAsync(formData, {
        onSuccess: () => {
          toast.success(`Record ${isUpdateForm ? 'Updated' : 'Added'} Successfully`);
          setTimeout(() => {
            navigate('/admin/october-webinar');
          }, 1000);
        },

        onError: () => {
          console.log('An error occurred while saving the data.');
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetLocale = (newLocale: string) => {
    // const isNewOption = Object.keys(octWebinarData.data).length === 0;

    // if (isNewOption) {
    //   setLocale('en');
    // } else {
    //   if (locale === 'en' && newLocale !== 'en') {
    //     for (const key in octWebinarData.data) {
    //       if (['tip_title', 'tip_description', 'thumbnail', 'video_title'].includes(key)) {
    //         continue;
    //       }

    //       if (
    //         octWebinarData.data[key] === undefined ||
    //         octWebinarData.data[key] === '' ||
    //         (octWebinarData.data[key] === 0 && key !== 'status')
    //       ) {
    //         toast.error(`Please fill in the ${key} field first`);
    //         return;
    //       }
    //     }
    //   }

    // }
    setLocale(newLocale);
  };

  return (
    <AdminWrapper>
      <Box className="pageHeader">
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h6"> {isUpdateForm ? 'Edit October Webinar' : 'Add October Webinar'} </Typography>
        </Box>
        <LocaleButtonGroup currentLocale={locale} setLocale={handleSetLocale} />
      </Box>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              fullWidth
              labelId="year-label"
              name="year_id"
              size="small"
              value={octWebinarData.data.year_id === 0 ? '' : octWebinarData.data.year_id.toString()}
              onChange={handleChange}
              disabled={disabledFields.includes('year_id')}
              required={locale === 'en'}
            >
              {(yearsData.data as YearData[]).map((year: YearData) => (
                <MenuItem key={year.id} value={year.id.toString()}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item sm={6}>
            <InputLabel id="year-label">
              Title <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add title"
              size="small"
              name="title"
              value={
                locale === 'en'
                  ? octWebinarData.data.title
                  : octWebinarData.translations[locale as keyof State['translations']]?.title || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('title')}
              fullWidth
            />
          </Grid>

          <Grid item sm={12}>
            <InputLabel id="year-label">
              Video <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add video"
              size="small"
              name="video_link"
              value={
                locale === 'en'
                  ? octWebinarData.data.video_link
                  : octWebinarData.translations[locale as keyof State['translations']]?.video_link || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('video_link')}
              fullWidth
            />
          </Grid>
          
          <Grid item sm={12}>
            <InputLabel id="year-label">
            Description <Typography component="sup">{locale.toUpperCase()}</Typography>
            </InputLabel>
            <TextField
              placeholder="Add video"
              size="small"
              name="description"
              value={
                locale === 'en'
                  ? octWebinarData.data.description
                  : octWebinarData.translations[locale as keyof State['translations']]?.description || ''
              }
              onChange={handleChange}
              disabled={disabledFields.includes('description')}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          
          <Grid item sm={6}>
            <InputLabel id="status-label">Status</InputLabel>
            <Grid container alignItems="center">
              <Typography>Inactive</Typography>
              <Switch
                id="status-label"
                name="status"
                onChange={handleChange}
                checked={Boolean(octWebinarData.data.status)}
                disabled={locale !== 'en'}
              />
              <Typography>Active</Typography>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <Button type="submit" variant="contained" color="primary" disabled={loading}>
              {loading && <CircularProgress size={16} sx={{ mr: 1 }} />} Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </AdminWrapper>
  );
};

export default AddWebinars;
