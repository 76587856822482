import { Box, Button, Container, Grid, Typography, FormLabel, TextField, Input, CircularProgress } from '@mui/material';
import { useAuthenticatedMutation, useAuthenticatedQuery } from 'csam/api/api';
import LocaleContext from 'csam/components/LocaleContext';
import React from 'react';
import InternalServerError from './InternalServerError';
import { fixed, getImage } from 'csam/utils/Constants';
import PageLoader from 'csam/components/common/PageLoader';
import Headerdark from 'csam/components/HeaderDark';
import OctPhishingKeyFetures from 'csam/components/OctPhishingKeyFetures';
import PhishingGuideline from 'csam/components/PhishingGuideline';
import NumbersBackground from 'csam/components/NumbersBackground';
import OctPhishingContestTranslations from 'csam/translations/OctPhishingContestTranslations';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

interface BannerData {
  id: number;
  year_id: number;
  heading: string;
  image: string;
  description: string;
  success: boolean;
}

interface DocumentData {
  id: number;
  contest_id: number;
  image: string;
  success: boolean;
}

interface UseMyApiCallParams {
  activityName: string;
  deps: string[];
}

interface DocumentContestData {
  activityExists: boolean;
  success: boolean;
  userActivityExists: boolean;
}


const OctPishingContest: React.FC = () => {
  const { locale } = React.useContext(LocaleContext);
  const localeFromStorage = localStorage.getItem('locale') || 'en';
  const [octContestActivity, setOctContestActivity] = React.useState<any>(null);
  const [octMemeContestActivity, setOctMemeContestActivity] = React.useState<any>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const contestId = queryParams.get('id');
  const contestType = queryParams.get('type');
  const currentYear = new Date().getFullYear();

  React.useEffect(() => {
    if (contestId && contestType) {
      sessionStorage.setItem('contestId', contestId);
      sessionStorage.setItem('contestType', contestType);
    }
  }, [contestId, contestType]);

  React.useEffect(() => {
    // Add class to body
    window.document.body.classList.add('octMonthLandingPage');

    // Cleanup function to remove class when component unmounts
    return () => {
      window.document.body.classList.remove('octMonthLandingPage');
    };
  }, []);

  React.useEffect(() => {
    const storedContestId = sessionStorage.getItem('contestId');
    const storedContestType = sessionStorage.getItem('contestType');
    if (storedContestId && storedContestType) {
      const currentPath = location.pathname.split('/').slice(2).join('/');
      navigate(
        {
          pathname: `/${locale}/${currentPath}`,
          search: `?id=${storedContestId}&type=${storedContestType}`,
        },
        { replace: true },
      );
    }
  }, [locale, location.pathname, navigate]);

  const contest_id = sessionStorage.getItem('contestId');

  const getTranslation = (key: keyof (typeof OctPhishingContestTranslations)['en']) =>
    OctPhishingContestTranslations[localeFromStorage as keyof typeof OctPhishingContestTranslations]?.[key] ||
    OctPhishingContestTranslations.en[key];

  const urlParams = new URLSearchParams(window.location.search);
  const isPhotoContest = urlParams.get('type') === 'photo-contest';
  const isDocumentContest = urlParams.get('type') === 'document-contest';
  const acceptFileTypes = isPhotoContest ? '.jpeg, .jpg, .png, .mp4, .mpeg' : '.doc, .docx, .txt, .pdf, .msg';

  const submitContestEndPoint = isPhotoContest ? 'phishing-photo-slogan' : 'phishing-contest-document';

  const userInfo = sessionStorage.getItem('userInfo');
  const parsedUserInfo = userInfo ? JSON.parse(userInfo) : null;
  const country = parsedUserInfo ? parsedUserInfo.country : '';
  const [errors, setErrors] = React.useState<{ emp_id?: string; image?: string; comment?: string; slogan?: string }>(
    {},
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [formData, setFormData] = React.useState({
    country,
    emp_id: '',
    image: null as File | null,
    comment: isPhotoContest ? undefined : '',
    slogan: isPhotoContest ? '' : undefined,
  });

  const pageName = 'Phishing Contest';
  const encodedPage = encodeURIComponent(pageName);
  const {
    isPending: bannerPending,
    error: bannerError,
    data: bannerData,
  } = useAuthenticatedQuery<BannerData>(['Banner', 'BannerData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/oct-banner?page=${encodedPage}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const mutation = useAuthenticatedMutation(['post-phising-contest', 'contestData'], (contestData) => ({
    url: `${fixed}${submitContestEndPoint}/submit`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: contestData,
  }));

  const {
    isPending: documentPending,
    error: documentError,
    data: documentData,
  } = useAuthenticatedQuery<DocumentData>(['Document', 'DocumentData', locale], {
    url: `${fixed}crud/${localeFromStorage || locale}/contest-document/${contest_id}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const {
    isPending: documentContestSubmissionPending,
    error: documentContestSubmissionError,
    data: documentContestSubmissionData,
    refetch: documentContestSubmissionRefetch,
  } = useAuthenticatedQuery<DocumentContestData>(['DocumentContest', 'DocumentContestData', locale], {
    url: `${fixed}crud/check-if-document-contest-activity-exists`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });  
  
  const {
    isPending: memeContestActivityPending,
    error: memeContestActivityError,
    data:  memeContestActivityData,
    refetch: memeContestActivityRefetch,
  } = useAuthenticatedQuery<DocumentContestData>(['MemeContest', 'MemeContestData', locale], {
    url: `${fixed}crud/check-if-meme-contest-activity-exists`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  const octDocumentContestMutaion = useAuthenticatedMutation(
    ['post-oct-document-submit-activity', 'PostData'],
    (postData) => ({
      url: `${fixed}crud/october-document-contest-activity`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postData,
    }),
  );

  const octMemeContestActivityMutation = useAuthenticatedMutation(
    ['post-oct-meme-submit-activity', 'PostData'],
    (postData) => ({
      url: `${fixed}crud/october-meme-contest-activity`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: postData,
    }),
  );

  const useMyApiCall = ({ activityName, deps }: UseMyApiCallParams) => {
    const body = React.useMemo(() => ({ activityName }), [activityName]);
    const x = useAuthenticatedQuery(
      activityName ? ['oct-contest', activityName, ...deps] : [],
      activityName
        ? {
            url: `${fixed}activity/addPoints`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: body,
          }
        : {
            url: '',
            method: 'GET',
            headers: {},
            data: {},
          },
    );

    return { isPending: x.isPending, error: x.error, data: activityName ? x.data : null };
  };

  const { data: apiContestData } = useMyApiCall({ activityName: octContestActivity, deps: [octContestActivity] });
  const { data: apiMemeContestData } = useMyApiCall({
    activityName: octMemeContestActivity,
    deps: [octMemeContestActivity],
  });

  React.useEffect(() => {
    if (apiContestData && apiContestData.success && apiContestData.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      toast.success(apiContestData.data?.message);
    }
  }, [apiContestData]);

  React.useEffect(() => {
    if (apiMemeContestData && apiMemeContestData.success && apiMemeContestData.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      toast.success(apiMemeContestData.data?.message);
    }
  }, [apiMemeContestData]);

  if (bannerPending) return <PageLoader />;
  if (bannerError) return <Box>Error: {bannerError.message}</Box>;
  if (bannerData && !bannerData.success) return <InternalServerError />;
  const banner = bannerData?.data;

  if (documentPending) return <PageLoader />;
  if (documentError) return <Box>Error: {documentError.message}</Box>;
  if (documentData && !documentData.success) return <InternalServerError />;
  const document = documentData?.data;

  if (documentContestSubmissionPending) return <PageLoader />;
  if (documentContestSubmissionError) return <Box>Error: {documentContestSubmissionError.message}</Box>;
  if (documentContestSubmissionData && !documentContestSubmissionData.success) return <InternalServerError />;
  const documentSubmited = documentContestSubmissionData?.data;
  const hideDocumentForm = documentSubmited.userActivityExists ? true : false;

  if (memeContestActivityPending) return <PageLoader />;
  if (memeContestActivityError) return <Box>Error: {memeContestActivityError.message}</Box>;
  if (memeContestActivityData && !memeContestActivityData.success) return <InternalServerError />;
  const memeContestActivity = memeContestActivityData?.data;
  const hideMemeForm = memeContestActivity.userActivityExists ? true : false;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
  
    if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024; 
  
      if (isPhotoContest) {
        // Validation for images and videos
        const validFileTypes = ['image/jpeg', 'image/png', 'video/mp4', 'video/mpeg'];
        if (validFileTypes.includes(file.type)) {
          if (['video/mp4', 'video/mpeg'].includes(file.type) && file.size > maxSizeInBytes) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              image: null,
            }));
            setErrors((prevErrors) => ({
              ...prevErrors,
              image: 'The video size must be less than 2MB.',
            }));
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              image: file,
            }));
            setErrors((prevErrors) => ({
              ...prevErrors,
              image: '',
            }));
          }
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            image: null,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            image: 'The file must be a file of type: jpeg, png, mp4, mpeg.',
          }));
        }
      } else {
        // Validation for documents
        const validFileTypes = [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/plain',
          'application/pdf',
          'application/vnd.ms-outlook',
        ];
        if (validFileTypes.includes(file.type)) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            image: file,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            image: '',
          }));
        } else {
          setFormData((prevFormData) => ({
            ...prevFormData,
            image: null,
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            image: 'The file must be a file of type: doc, docx, txt, pdf, msg.',
          }));
        }
      }
    }
  };

  const submitContestForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let formErrors = {} as typeof errors;

    if (!formData.emp_id) {
      formErrors.emp_id = 'Employee ID cannot be blank';
    }

    if (!formData.image) {
      formErrors.image = 'This field cannot be blank';
    } else {
      if (isPhotoContest) {
        // Validation for images and videos
        const validFileTypes = ['image/jpeg', 'image/png', 'video/mp4', 'video/mpeg'];
        if (!validFileTypes.includes(formData.image.type)) {
          formErrors.image = 'The file must be a file of type: jpeg, png, mp4, mpeg.';
        }
      } else {
        // Validation for documents
        const validFileTypes = [
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'text/plain',
          'application/pdf',
          'application/vnd.ms-outlook',
        ];
        if (!validFileTypes.includes(formData.image.type)) {
          formErrors.image = 'The file must be a file of type: doc, docx, txt, pdf, msg.';
        }
      }
    }

    if (!isPhotoContest && !formData.comment) {
      formErrors.comment = 'Comment cannot be blank';
    }

    if (isPhotoContest && !formData.slogan) {
      formErrors.slogan = 'Slogan cannot be blank';
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append('country', formData.country);
    if (isPhotoContest) {
      formDataToSend.append('slogan', formData.slogan!);
    } else {
      formDataToSend.append('comment', formData.comment!);
    }
    formDataToSend.append('emp_id', formData.emp_id.toString());
    if (formData.image) {
      formDataToSend.append('image', formData.image);
    }

    let itemId = contestId;
    const mutationData = {
      itemId: itemId,
    };

    mutation.mutate(formDataToSend, {
      onSuccess: () => {
        setFormData({ country: formData.country, emp_id: '', comment: '', slogan: '', image: null });
        setIsSubmitting(false);
        memeContestActivityRefetch();
        documentContestSubmissionRefetch();
        toast.success('Contest has been submitted successfully');
      },
      onError: (error) => {
        console.error('Failed to make a comment:', error);
        setIsSubmitting(false);
      },
    });

    if (isDocumentContest) {
      if (itemId) {
        octDocumentContestMutaion.mutate(mutationData, {
          onSuccess: () => {
            console.log('Data saved successfully');
            setTimeout(() => {
              setOctContestActivity(`${currentYear}_${contestId}_october_document_subimt_activity`);
            }, 1000);
          },
          onError: () => {
            console.log('An error occurred while saving the oct contest activity.');
          },
        });
      }
    }

    if (isPhotoContest) {
      if (itemId) {
        octMemeContestActivityMutation.mutate(mutationData, {
          onSuccess: () => {
            console.log('Data saved successfully');
            setTimeout(() => {
              setOctMemeContestActivity(`${currentYear}_${contestId}_october_meme_subimt_activity`);
            }, 1000);
          },
          onError: () => {
            console.log('An error occurred while saving the oct contest activity.');
          },
        });
      }
    }
  };

  return (
    <Box className="bg-black">
      <Headerdark />
      <Box
        className="pageBannerActivity bannerPledge"
        {...(banner.image ? { style: { backgroundImage: `url(${getImage}oct_banners/${banner.image})` } } : {})}
      >
        {banner.heading && (
          <Container>
            <Box className="banner_title">
              {banner.heading && <Typography component="h2">{banner.heading}</Typography>}
            </Box>
          </Container>
        )}
      </Box>

      <Box className="main-section">
        {/* phishing key fetures */}
        <OctPhishingKeyFetures documentData={document} />

        {/* contest guidelines */}
        <PhishingGuideline />

        {/* numbers background */}
        <NumbersBackground />

        {/* contest submission form */}
        <Box className="entries-here">
          <Container sx={{ pb: 5, pt: 5 }}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Box className="please-leave aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000">
                  <span>{getTranslation('formSubHeading')}</span>
                  <span className="questions">{getTranslation('formHeading')}</span>
                  <img
                    className="arrow-show"
                    src="https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public/storage/2022/images/arrow-show.png"
                    alt="arrow"
                  />
                  <img
                    className="anchor-arrow"
                    src="https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public/storage/2022/images/anchor-arrow.png"
                    alt="arrow"
                  />
                  <img
                    className="email-phone"
                    src="https://storage.googleapis.com/abi-ghq-cybersecurity-prd-gcs/public/storage/2022/images/email.png"
                    alt="arrow"
                  />
                </Box>
              </Grid>

              {!hideDocumentForm && isDocumentContest || !hideMemeForm && isPhotoContest ? (
                <Grid item md={8}>
                  <Box
                    component="form"
                    encType="multipart/form-data"
                    className="formContestPhishing"
                    onSubmit={submitContestForm}
                  >
                    <FormLabel className="d-block">{getTranslation('label1')}</FormLabel>
                    <TextField
                      size="small"
                      name="emp_id"
                      type="number"
                      value={formData.emp_id}
                      placeholder={getTranslation('plaeholder1')}
                      onChange={handleInputChange}
                      error={!!errors.emp_id}
                    />
                    {errors.emp_id && <Typography className="error-text">{errors.emp_id}</Typography>}

                    <FormLabel className="d-block">{getTranslation('label2')}</FormLabel>
                    <Input
                      type="file"
                      className="imageUpload no-focus-border"
                      inputProps={{ accept: acceptFileTypes }}
                      onChange={handleImageChange}
                      error={!!errors.image}
                    />
                    {errors.image && <Typography className="error-text">{errors.image}</Typography>}

                    <FormLabel className="d-block">{getTranslation('label3')}</FormLabel>
                    <textarea
                      name={isPhotoContest ? 'slogan' : 'comment'}
                      className="commentMessageContest"
                      placeholder={getTranslation('plaeholder2')}
                      value={isPhotoContest ? formData.slogan : formData.comment}
                      onChange={handleInputChange}
                      aria-multiline
                      rows={4}
                    />
                    {(isPhotoContest ? errors.slogan : errors.comment) && (
                      <Typography className="error-text" style={{ top: '2px' }}>
                        {isPhotoContest ? errors.slogan : errors.comment}
                      </Typography>
                    )}

                    <Button type="submit" variant="contained" className="submitPhishingContest" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <CircularProgress sx={{ color: 'white' }} size={24} />
                      ) : (
                        getTranslation('submitbtn')
                      )}
                    </Button>

                    <small className="d-block text-center">{getTranslation('formFooterText')}</small>
                  </Box>
                </Grid>
              ) : (
                <Grid item md={8} className="d-flex align-items-center">
                  <Box className="formContestPhishing">
                    <Typography variant="h5" className="text-center">
                      You have successfully submited the contest
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default OctPishingContest;
